$(document).on('turbolinks:load', function() {
  var events_url = location.pathname.replace(/calendar$/, 'events.json')
  var query = location.search
  if (query) {
    events_url += query
  }

  $('#reservation_calendar').empty()

  const params = calendar_base_params()

  params.header.right = 'month agendaWeek'

  Object.assign(params, {
    // The initial view when the calendar loads.
    defaultView: 'month',
    // A URL of a JSON feed that the calendar will fetch Event Objects from.
    //events (as a json feed)
    events: {
      url: events_url,
      data: function(){
        return {
          user_id: $('#user_id').val(),
          requested_setting_id: $('#requested_setting_id').val(),
        }
      }
    },
    //===== Date Clicking & Selecting =====
    // Detect when the user clicks on dates or times. Give the user the ability to select multiple dates or time slots with their mouse or touch device.

    // Allows a user to highlight multiple days or timeslots by clicking and dragging.
    selectable: true,

    // Whether to draw a “placeholder” event while the user is dragging.
    //selectMirror:

    // Whether clicking elsewhere on the page will cause the current selection to be cleared.
    unselectAuto: true,

    // A way to specify elements that will ignore the unselectAuto option.
    unselectCancel: '',

    //----- CALLBACKS -----
    // Triggered when the user clicks on a date or a time.
    //dateClick:

    // Triggered when a date/time selection is made.
    //select: function(start, end, allDay) {
    //  console.log('select called: start=', start, ', end=', end, ', allDay=', allDay)
    //},

    // Triggered when the current selection is cleared.
    //unselect:

    //===== Event Sources =====

    // An array of Event Objects that will be displayed on the calendar.
    //events (as an array)

    // A custom function for programmatically generating Events.
    //events (as a function)

    //===== Event Clicking & Hovering =====
    // Handers to know when an event has been clicked or hovered over.

    // Triggered when the user clicks an event.
    eventClick: function(calEvent, jsEvent, view) {
      // console.log('eventClick called: calEvent=', calEvent, ', jsEvent=', jsEvent)
      // console.log(calEvent)
      // console.log(jsEvent)
      // console.log(view)
    },

    //===== International =====
    eventColor: 'rgba(150,150,150,0.2)',
    disableDragging: true
  })

  calendar = $('#reservation_calendar').fullCalendar(params)
  $('[data-refetch-trigger]').on('change' , function(){
    calendar.fullCalendar('refetchEvents');
  });
})
