$(document).on('ready turbolinks:load', function() {
  $('[data-mail-template-keyword-to-clipboard]').on('click', mail_template_keyword_to_clipboard)
});

function mail_template_keyword_to_clipboard(evt) {
  const keyword = $(evt.target).text().trim()
  const userAgent = window.navigator.userAgent.toLowerCase()
  const $copytmp = $('<textarea/>')
  $copytmp.html(keyword)
  $('body').append($copytmp)
  if(userAgent.indexOf('chrome') != -1){
    $copytmp.select()
  } else {
    const range = document.createRange()
    range.selectNode($copytmp[0].firstChild)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }
  document.execCommand('copy')
  $copytmp.remove()
}
