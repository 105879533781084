$(document).on('ready turbolinks:load', function() {
  $("input[name='reservation[request_for_exemption]'").change(function() {
    if ($(this).prop('checked')) {
      $("select[name='reservation[payment_method]']").prop('disabled', true)
      $(".reservation_reason_for_exemption").slideDown()
      //$(".reservation_reason_for_exemption").css('visibility', 'visible')
    } else {
      $("select[name='reservation[payment_method]']").prop('disabled', false)
      $(".reservation_reason_for_exemption").slideUp()
      //$(".reservation_reason_for_exemption").css('visibility', 'hidden')
    }
  })

  $("[data-payment-method-trigger]").on('change', function() {
    // 利用目的によっては料金が0になることがある
    const $target = $("[data-payment-method]");
    if ($(this).find(':selected').data('price') > 0) {
      $target.show();
      $target.find(':input').prop('disabled', false);
      $("input[name='reservation[request_for_exemption]']").trigger('change');
    } else {
      $target.hide();
      $target.find(':input').prop('disabled', true);
    }
  });
  $("[data-payment-method-trigger]").trigger('change');

  // 申請書作成ボタン
  $('[data-reservation-to-clipboard]').on('click', reservation_to_clipboard)
});

$(window).on('pageshow', function() {
  // これがないとブラウザの戻るで予約申請画面に来た場合おかしくなる
  $("[data-payment-method-trigger]").trigger('change');
  $("input[name='reservation[request_for_exemption]']").trigger('change');
});

// 予約情報をクリップボードへ入れる
// コピーした内容を入れたテキストエリアを一瞬documentに追加し、
// テキストを選択、コピーコマンドを実行 を実施する必要がある
function reservation_to_clipboard(){
  const userAgent = window.navigator.userAgent.toLowerCase();
  const $copydata = $('#copydata');
  const $copytmp = $('<textarea></textarea>');
  // 減免率入力フォームが画面にある場合はその値を使う
  const $exemption_percent = $('select[name="reservation[exemption_percent]"]');
  if($exemption_percent[0]){
    $copydata.find('.exemption_percent').text($exemption_percent.find(':selected').val());
  }
  $copytmp.html($copydata.find('td').map(function(i, el){return $(el).text().trim()}).get().join('\n'));
  $('body').append($copytmp);
  if(userAgent.indexOf('chrome') != -1 ){
    $copytmp.select();
  } else {
    const range = document.createRange();
    range.selectNode($copytmp[0].firstChild);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
  document.execCommand('copy');
  $copytmp.remove();
  alert('申請書に必要なデータをコピーしました\n申請書の所定の位置に貼り付けてください');
}
