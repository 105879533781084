$(document).on('ready turbolinks:load', function() {
  flatpickr.localize(flatpickr.l10ns.ja)
  flatpickr("[data-behavior='flatpickr']", {
    enableTime: true,
    dateFormat: 'Y-m-d H:i',
    time_24hr: true,
    minDate: 'today',
    minuteIncrement: 10,
    disableMobile: false
  })
});
