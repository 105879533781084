$(document).on('turbolinks:load', function() {
  $(this).find('[data-user-select2]').select2({
    ajax: {
      url: '/user_resources/search',
      dataType: 'json'
    }
  });

  $('[id^="reservation_modal_"]').on('shown.bs.modal', function (e) {
    $(this).find('[data-modal-user-select2]').select2({
      ajax: {
        url: '/user_resources/search',
        dataType: 'json'
      },
      dropdownParent: $(this).find('.modal-body')
    });
  })
});
