$(document).on('turbolinks:load', function() {
  if ($('.price-calendar-show').length){
    $('#price-calendar-readonly-weekday, #price-calendar-readonly-holiday').empty();
    const params = calendar_base_params();
    Object.assign(params, {
      header: {
        left: '',
        center: '',
        right: ''
      },
      defaultView: 'agendaDay',
      minTime: '00:00:00',
      maxTime: '24:00:00',
      selectHelper: true,
      ignoreTimezone: false,
      editable: false,
      droppable: false,
      displayEventEnd: true,
      eventColor: 'gray',
      eventTextColor: "black",
    });

    const $weekday_calendar = $('#price-calendar-readonly-weekday');
    const $holiday_calendar = $('#price-calendar-readonly-holiday');
    params.columnHeaderText = function(date) { return '平日' };
    $weekday_calendar.fullCalendar(params);
    params.columnHeaderText = function(date) { return '土曜、日曜、祝日' };
    $holiday_calendar.fullCalendar(params);

    $.getJSON([location.pathname, '.json'].join(''))
      .done(function(data) {
        let event_list = { 'weekday': [], 'holiday': [] };
        $.each(data, function(i, v){
          event_list[v.date_type].push({
            title: '\\ ' + v.yen,
            start: v.checkin,
            end: v.checkout,
          })
        });
        $weekday_calendar.fullCalendar('addEventSource', event_list['weekday'], true);
        $holiday_calendar.fullCalendar('addEventSource', event_list['holiday'], true);
        $weekday_calendar.fullCalendar('rerenderEvents');
        $holiday_calendar.fullCalendar('rerenderEvents');
      });
  }
});
