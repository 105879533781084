$(document).on('turbolinks:load', function() {

  $('.welcome-image-slider').slick({
    dots: true,
    //infinite: true,
    slidesToShow: 3,
    //slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 1200,
    centerMode: true,
    //accessibility: true,
    //centerPadding: '50px',
    //prevArrow:'<i class="fa fa-angle-left arrow arrow-left"></i>',
    //nextArrow:'<i class="fa fa-angle-right arrow arrow-right"></i>',
  })

  $('.facility-index-image-slider, .room-index-image-slider').slick({
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    initialSlide: 0,
    centerMode: true,
    arrows: false,
    variableWidth: true,
    centerPadding: '0'
  })

  $(".facilities.index .slick-list, .rooms.index .slick-list").each(function(){
    var list = $(this);
    var faImgList = new Array();
    list.find("img").each(function(index){
      faImgList[index] = $(this).height();
    });
    var maxHeight = Math.max.apply(null, faImgList);
    list.parents(".media-photo").css({"height":maxHeight + "px"});
  });


  $el = $('.facility-show-image-slider, .room-show-image-slider')
  if($el[0]){
    $('.facility-show-image-slider, .room-show-image-slider').slick({
      dots: true,
      //infinite: true,
      slidesToShow: 1,
      //slidesToScroll: 1,
      arrows: true,
      //centerMode: true,
      //autoplay: true,
      //autoplaySpeed: 5000,
      //centerMode: true,
      //centerPadding: '10%'
    });
  }
  // 画像が表示されないことがあるが、リサイズすると出るので無理やり出す
  setTimeout(function(){
    $(window).trigger('resize');
  }, 1000);
});

$(window).resize(function() {
  $(".facilities.index .slick-list, .rooms.index .slick-list").each(function(){
    var list = $(this);
    var faImgList = new Array();
    list.find("img").each(function(index){
      faImgList[index] = $(this).height();
    });
    var maxHeight = Math.max.apply(null, faImgList);
    list.parents(".media-photo").css({"height":maxHeight + "px"});
  });
});

$(window).on('pageshow', function() {
  // 画像が表示されないことがあるが、リサイズすると出るので無理やり出す
  setTimeout(function(){
    $(window).trigger('resize');
  }, 1000);
});