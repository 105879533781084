$(document).on('turbolinks:load', function() {
  if ($('.price-calendar-create').length){
    $('#price-calendar-weekday, #price-calendar-holiday').empty();
    const params = calendar_base_params();
    Object.assign(params, {
      // Defines the buttons and title at the top of the calendar.
      header: {
        //left: 'today prev,next title',
        left: '',
        center: '',
        //right: 'month agendaWeek agendaDay'
        right: ''
      },
      // The initial view when the calendar loads.
      defaultView: 'agendaDay',
      minTime: '00:00:00',
      maxTime: '24:00:00',
      selectHelper: true,
      ignoreTimezone: false,

      // FIXME eventDrop, eventResize
      editable: false,
      // editable: true,

      droppable: false,
      displayEventEnd: true,
      //timeFormat: 'HH:mm',
      eventColor: 'rgba(150,150,150,0.2)',
      eventTextColor: "green",

      // Triggered when a date/time selection is made.
      select: function(start, end, jsEvent, view, resource) {
        createEvent(start, end, view);
      },
      eventDrop: function(event, delta, revertFunc, jsEvent, ui, view) {
        // FIXME TypeError: this.eventInstances[0] is undefined
        // May need to upgrade fullcalendar ver3.10.0 >> ver4.3.0~
        // Since there are many destructive changes, we will postpone it this time.
        // ref: https://github.com/fullcalendar/fullcalendar/issues/4127
        //      https://github.com/Apexal/late/issues/155

        // moveEvent(event, view);
      },
      eventResize: function(event, delta, revertFunc, jsEvent, ui, view) {
        // FIXME TypeError: this.eventInstances[0] is undefined
        // May need to upgrade fullcalendar ver3.10.0 >> ver4.3.0~
        // Since there are many destructive changes, we will postpone it this time.
        // ref: https://github.com/fullcalendar/fullcalendar/issues/4127
        //      https://github.com/Apexal/late/issues/155

        // moveEvent(event, view);
      },
      eventClick: function(calEvent, jsEvent, view) {
        // updateEvent(calEvent, view);
        removeEvent(calEvent, view);
      },
    });
    params.columnHeaderText = function(date) { return '平日' };
    $('#price-calendar-weekday').fullCalendar(params);
    params.columnHeaderText = function(date) { return '土曜、日曜、祝日' };
    $('#price-calendar-holiday').fullCalendar(params);

    let input_values = {};
    // polyfill
    const isInteger = function(val){
          return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
    }
    const eventForm = function(start, end, yen, date_type) {
      const price = prompt('価格を入力して下さい: ', yen);

      let err_msg = '';
      if (price == null || price === '') {
        err_msg = '価格が入力されていません'
      } else if (!$.isNumeric(price)){
        err_msg = '数字を入力してください'
      } else if (isInteger(parseFloat(price))){
        err_msg = '整数で入力してください'
      } else if (parseInt(price) < 0){
        err_msg = 'マイナスの値は入力できません'
      }
      if (err_msg.length) {
        alert(err_msg);
        return null
      }
      var ret = {
        checkin: start.format('HH:mm:ss'),
        checkout: end.format('HH:mm:ss'),
        yen: parseInt(price),
        date_type: date_type,
      }
      const start_of_day = start.hour(0).minute(0).seconds(0)
      if (end - start_of_day >= 24 * 60 * 60 * 1000) {
        ret['checkout'] = '23:59:59'
      }
      return ret;
    };

    const set_hidden_values = function(input_values) {
      $('.prices-hidden-field').empty();
      $.each(input_values, function(k, v){
        $('<input>').attr({ type: 'hidden', name: 'checkins[]', value: v.checkin }).appendTo('.prices-hidden-field');
        $('<input>').attr({ type: 'hidden', name: 'checkouts[]', value: v.checkout }).appendTo('.prices-hidden-field');
        $('<input>').attr({ type: 'hidden', name: 'yens[]', value: v.yen }).appendTo('.prices-hidden-field');
        $('<input>').attr({ type: 'hidden', name: 'date_types[]', value: v.date_type }).appendTo('.prices-hidden-field');
      });
    };

    const set_calendar_values = function(input_values) {
      for(let i = 0; i < 2; i ++){
        this_calendar = [$('#price-calendar-weekday'),$('#price-calendar-holiday')][i]
        let event_list = [];
        $.each(input_values, function(k, v){
          if(this_calendar.data('date-type') !== v.date_type){return true}
          event_list.push({
            id: k,
            date_type: v.date_type,
            title: '\\ ' + v.yen,
            yen: v.yen,
            start: v.checkin,
            end: v.checkout,
          })
        });
        // console.log(event_list)
        this_calendar.fullCalendar('removeEvents');
        this_calendar.fullCalendar('addEventSource', event_list, true);
        this_calendar.fullCalendar('rerenderEvents');
        this_calendar.fullCalendar('unselect');
      }
    };

    const createEvent = function(start, end, view) {
      const this_calendar = $(view.el).parents('.price-calendar');
      const date_type = this_calendar.data('date-type');

      const data = eventForm(start, end, 0, date_type);
      if (data == null) {
        this_calendar.fullCalendar('unselect');
        return false;
      }

      const temporary_key = Date.now().toString(16);
      $.extend(input_values, {[temporary_key]: data});
      set_hidden_values(input_values);
      set_calendar_values(input_values);
    };

    const updateEvent = function(event, view) {
      const this_calendar = $(view.el).parents('.price-calendar');
      const date_type = this_calendar.data('date-type');

      const data = eventForm(event.start, event.end, event.yen, date_type);
      if (data == null) {return false}

      input_values[event.id] = data;
      set_hidden_values(input_values);
      set_calendar_values(input_values);
    };

    const moveEvent = function(event, view) {
      const this_calendar = $(view.el).parents('.price-calendar');

      input_values[event.id]['checkin'] = event.start.format('HH:mm:ss');
      input_values[event.id]['checkout'] = event.end.format('HH:mm:ss');
      set_hidden_values(input_values);
      set_calendar_values(input_values);
    };

    var removeEvent = function(event, view) {
      const this_calendar = $(view.el).parents('.price-calendar');
      var res = confirm("本当に削除しますか？");
      if (res === false) {return false}

      delete input_values[event.id];
      set_hidden_values(input_values);
      set_calendar_values(input_values);
    };
  }
});
